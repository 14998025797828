import React from "react"
import ReactDOM from "react-dom"
import Flickity from "react-flickity-component"
import { Link } from "gatsby"

import "../assets/css/flickity.css"

function Agents(props) {

  const flickityOptions = {
    groupCells: true
  };

  return (
    <Flickity className="w-full mb-10" options={flickityOptions}>
      {(props.agency.agency_agents).map((agent, i) => 
        (
          <div className="mt-5 carousel-cell p-2" key={i}>
            {props.agency.agency_type === "Recruit" ? ( 
              <div className="bg-gray-100 rounded-lg h-56 pt-2">
                <img className="object-cover h-20 w-20 mx-auto shadow-md border-2 border-white rounded-full"
                    src={agent.avatar.url}
                    alt={agent.avatar.url}
                  />
                <div className="text-center mt-2">
                  <p className="font-bold text-gray-800">{agent.title}</p>
                  <p className="text-xs font-medium text-gray-500">
                    {agent.designation != null ? agent.designation.replace("_"," ") : " "}
                  </p> 
                </div>
                <div className="flex flex-wrap justify-center mt-2">
                {agent.agent_areas_serviced.slice(0, 2).map((area, i) => {
                    return (
                      <p className="inline-block p-1 m-1 rounded-lg text-xs text-gray-700 bg-gray-200">{area.areaName}</p>
                    )
                  })}

                {agent.agent_areas_serviced.length > 2 &&
                  <p className="inline-block p-1 m-1 rounded-lg text-xs text-gray-700 bg-gray-200">{agent.agent_areas_serviced.length - 2} +</p>
                }

                </div>
              </div>
            ) : ( 
              <Link to={`/agent/${agent.slug}`} key={`agent__${agent.id}`}>
              <div className="bg-gray-100 rounded-lg h-56 pt-2">
                <img className="object-cover h-20 w-20 mx-auto shadow-md border-2 border-white rounded-full"
                    src={agent.avatar.url}
                    alt={agent.avatar.url}
                  />
                <div className="text-center mt-2">
                  <p className="font-bold text-gray-800">{agent.title}</p>
                  <p className="text-xs font-medium text-gray-500">
                    {agent.designation != null ? agent.designation.replace(/_/g," ") : " "}
                  </p> 
                </div>
                <div className="flex flex-wrap justify-center mt-2">
                {agent.agent_areas_serviced.slice(0, 2).map((area, i) => {
                    return (
                      <p className="inline-block p-1 m-1 rounded-lg text-xs text-gray-700 bg-gray-200">{area.areaName}</p>
                    )
                  })}

                {agent.agent_areas_serviced.length > 2 &&
                  <p className="inline-block p-1 m-1 rounded-lg text-xs text-gray-700 bg-gray-200">{agent.agent_areas_serviced.length - 2} +</p>
                }

                </div>
              </div>
            </Link>
             )}
          </div>
        )
      )}
    </Flickity>
  )
}

export default Agents