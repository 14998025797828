import React, { useState } from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"
import ReactMarkdown from "react-markdown"
import { useUrlSearchParams } from "use-url-search-params"
import { Controller, Scene } from "react-scrollmagic"
import CookiesModul from "../components/cookiesmodul"

import LayoutNoNav from "../components/layout_no_nav"
import SEO from "../components/seo"
import ListingsComponent from "../components/listings"
import logo from "../images/logo.svg"
import Agents from "../components/agents"

import waveReverse from "../images/wave_reverse.svg"

import AgencyResidentialForm from "../components/agency_residential_form"
import AgencyRecruitForm from "../components/agency_recruit_form"
import AgencyCommercialForm from "../components/agency_commercial_form"

import AgencyResidentialFormMobile from "../components/agency_residential_form_mobile"
import AgencyRecruitFormMobile from "../components/agency_recruit_form_mobile"
import AgencyCommercialFormMobile from "../components/agency_commercial_form_mobile"

export const query = graphql`
  query AgencyQuery($id: String!) {
    strapiAgency(strapiId: { eq: $id }) {
      strapiId
      title
      slug
      address
      description
      rating
      social_facebook
      social_twitter
      social_instagram
      social_linkedin
      lead_form_url
      listings_url
      all_listings_url
      organisation_id
      recruit
      video
      video_url
      agency_type
      areas_serviced {
        areaName
      }
      cover {
        url
      }
      image {
        url
      }
      agency_agents {
        id
        title
        designation
        rating
        slug
        agent_areas_serviced {
          areaName
        }
        avatar {
          url
        }
      }
    }
  }
`

const Agency = ({ data }) => {
  const agency = data.strapiAgency
  const [params] = useUrlSearchParams({})

  const [readMore, setReadMore] = useState(false)
  const linkName = readMore ? "Read Less" : "Read More"

  let descriptionContent
  if (!readMore) {
    descriptionContent = (
      <ReactMarkdown source={agency.description.slice(0, 200)} />
    )
  } else {
    descriptionContent = <ReactMarkdown source={agency.description} />
  }







  let renderListings

  if (agency.listings_url !== undefined ) {
    if (agency.listings_url != null && agency.listings_url.length >= 1 ) {
    renderListings = (
      <div className="w-full">
      <div className="container mx-auto mt-5 px-4">
        <h3 className="font-medium text-xl">Properties on the market</h3>
        <a
          href={agency.all_listings_url}
          className="text-sm text-orange-500 underline"
        >
          View all listings
        </a>
      </div>

      <ListingsComponent listings={agency.listings_url} />
    </div>
    )
    }
  } else {
    renderListings = ""
  }
  





  let socialFacebook
  let socialTwitter
  let socialInstagram
  let socialLinkedin

  if (agency.social_facebook.length > 1) {
    socialFacebook = (
      <a href={agency.social_facebook} target="_blank">
        <i className="border rounded-full text-orange-500 border-orange-500 hover:bg-orange-500 hover:text-white cursor-pointer p-2 mr-1 md:mx-1 fab fa-facebook"></i>
      </a>
    )
  } else {
    socialFacebook = ""
  }

  if (agency.social_twitter.length > 1) {
    socialTwitter = (
      <a href={agency.social_twitter} target="_blank">
        <i className="border rounded-full text-orange-500 border-orange-500 hover:bg-orange-500 hover:text-white cursor-pointer p-2 mr-1 md:mx-1 fab fa-twitter"></i>
      </a>
    )
  } else {
    socialTwitter = ""
  }

  if (agency.social_instagram.length > 1) {
    socialInstagram = (
      <a href={agency.social_instagram} target="_blank">
        <i className="border rounded-full text-orange-500 border-orange-500 hover:bg-orange-500 hover:text-white cursor-pointer p-2 mr-1 md:mx-1 fab fa-instagram"></i>
      </a>
    )
  } else {
    socialInstagram = ""
  }

  if (agency.social_linkedin.length > 1) {
    socialLinkedin = (
      <a href={agency.social_linkedin} target="_blank">
        <i className="border rounded-full text-orange-500 border-orange-500 hover:bg-orange-500 hover:text-white cursor-pointer p-2 mr-1 md:mx-1 fab fa-linkedin"></i>
      </a>
    )
  } else {
    socialLinkedin = ""
  }

  function SwitchCaseForm(props) {
    switch(agency.agency_type) {
      case 'Residential':
        return <AgencyResidentialForm agency={agency} params={params} />;
      case 'Commercial':
        return <AgencyCommercialForm agency={agency} params={params} />;
      case 'Recruit':
        return <AgencyRecruitForm agency={agency} params={params} />;
    } 
  }

  function SwitchCaseFormMobile(props) {
    switch(agency.agency_type) {
      case 'Residential':
        return <AgencyResidentialFormMobile agency={agency} params={params} />;
      case 'Commercial':
        return <AgencyCommercialFormMobile agency={agency} params={params} />;
      case 'Recruit':
        return <AgencyRecruitFormMobile agency={agency} params={params} />;
    } 
  }

  return (
    <LayoutNoNav>
      <SEO
        title={agency.title}
        description={agency.description}
        image={agency.image.url}
      />

      <div className="md:hidden flex fixed justify-between p-2 bottom-0 border-t border-gray-300 z-50 bg-white w-full">
        <div className="flex">
          <img
            className="object-cover h-10 mx-auto border-2 border-gray-200 rounded-lg"
            src={agency.image.url}
            alt={agency.image.url}
          />
          <p className="inline-block self-center text-sm font-bold ml-2">
            {agency.title}
          </p>
        </div>
        <button
          onClick={() => scrollTo("#agency-form-scroll")}
          className="inline-block self-center text-sm bg-orange-500 hover:bg-orange-700 text-white font-medium py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline"
        >
          Send Message
        </button>
      </div>

      <div
        className={`flex items-center fixed z-50 top-0 left-0 w-full h-full ${
          params.thankyou ? "block" : "hidden"
        }`}
      >
        <div className="bg-white shadow sm:rounded-lg mx-auto z-40">
          <div className="px-4 py-5 sm:p-6">
            <div className="sm:flex sm:items-start sm:justify-between">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Message Sent
                </h3>
                <div className="mt-2 max-w-xl text-sm text-gray-500">
                  <p>
                    Thank you for submit your enquiry. A message has been sent
                    and an agent will be in contact with you ASAP.
                  </p>
                </div>
              </div>
              <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
                <a
                  href={`/agency/${agency.slug}`}
                  className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-lg text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                >
                  Close
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-25 z-30"></div>
      </div>

      <div className="bg-gray-100 w-full mt-n5">
        <div className="container mx-auto pt-10 px-5 ">
          <span className="text-sm text-gray-500">Agencies</span>
          <span className="text-sm text-gray-400"> / </span>
          <span className="text-sm text-gray-800 font-medium">
            {agency.title}
          </span>
        </div>

        <div className="container mx-auto bg-white shadow rounded-lg mt-5">


          <img className="object-center object-cover w-full md:rounded-t-lg h-48 md:h-banner" src={agency.cover.url}></img>

          <div className="flex flex-col md:flex-row p-5 mt-n12">
            <div className="w-full md:w-2/3">
              <div className="flex flex-col md:flex-row">
                <img
                  className="object-cover h-24 md:h-32 w-32 md:w-auto mx-auto border-2 border-gray-200 rounded-lg"
                  src={agency.image.url}
                  alt={agency.image.url}
                />

                <div className="flex-1 md:ml-5 text-center md:text-left">
                  <h1 className="text-xl md:text-2xl font-bold mt-5 md:mt-10">
                    {agency.title}
                  </h1>
                  <h2 className="text-xs md:text-sm text-gray-600">
                    {agency.address}
                  </h2>
                  <div className="w-full text-orange-500 mt-2">
                    {socialFacebook}
                    {socialTwitter}
                    {socialInstagram}
                    {socialLinkedin}
                  </div>
                </div>
              </div>

              <p className="text-gray-500 mt-5">
                <span className="markdown">{descriptionContent}</span>
                <a
                  className="text-orange-500 cursor-pointer"
                  onClick={() => {
                    setReadMore(!readMore)
                  }}
                >
                  {linkName}
                </a>
              </p>

              { agency.areas_serviced.length >= 1  && 
                <div className="w-full">  
                  <hr className="border-t border-gray-200 my-5" />

                  <h3 className="font-medium text-md">Areas Serviced</h3>

                  <div className="flex flex-wrap mt-5">
                    {agency.areas_serviced.map((area, i) => {
                      return (
                        <div className="w-1/3">
                          <p className="text-sm text-gray-500">{area.areaName}</p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              }

              {agency.video ? (
              <div>
                <hr className="border-t border-gray-200 my-5" />
                <h3 className="font-medium text-md">Video</h3>
                <iframe className="w-full h-48 md:h-72 mt-5"
                  src={agency.video_url}>
                </iframe>
              </div>
              ) : (
                <div>
                </div>            
              )}

              { agency.agency_agents.length >= 1  && 
                <div className="w-full">  
                  <hr className="border-t border-gray-200 my-5" />

                  <h3 className="font-medium text-md">Agents</h3>

                  <Agents agency={agency}/>
                </div> 
              }

              <hr
                id="agency-form-scroll"
                className="border-t border-gray-100 my-5"
              />
            </div>

            <div className="w-full md:w-1/3 mt-5 md:mt-0 md:ml-5 hidden md:block">
              <Controller className="mt-0">
                <Scene duration={300} pin triggerHook={0.25} offset={100}>
                  <SwitchCaseForm value={agency.agency_type} />
                </Scene>
              </Controller>
            </div>

            <div className="w-full md:w-1/3 mt-5 md:mt-0 md:ml-5 md:hidden">
              <SwitchCaseFormMobile value={agency.agency_type} />
            </div>
          </div>
        </div>

        {renderListings}

        <img className="w-full mt-24" src={waveReverse} alt="" />
      </div>

      <footer className="container mx-auto">
        <div className="px-4 md:px-10 py-24 text-center">
          <img className="h-4 mx-auto" src={logo} alt="" />
          <p className="w-full text-xs text-gray-400 mt-4">
            Copyright © 2021 Flow Living (PTY) Ltd. All rights reserved. <br />
            <a 
            href="/termsofuse" 
            target="_blank"
            className="underline mx-1"
          >
            Terms of use
          </a>
            <a
              href="/privacypolicy" 
              target="_blank" 
              className="underline mx-1">
              Privacy Policy
            </a>
          </p>
        </div>
      </footer>
      <CookiesModul />
    </LayoutNoNav>
  )
}

export default Agency
