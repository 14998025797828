import React from "react"
import Cookies from 'universal-cookie';

function handleClickMobile(e) {
  const currentFormMobile = document.getElementById("agency-form-mobile")

  if (currentFormMobile.checkValidity() == true) {
    e.target.disabled = true
    e.target.innerText = "Submitting..."
    currentFormMobile.submit()
  }
}

const AgencyResidentialFormMobile = ({ agency, params }) => {

  const cookies = new Cookies();

  return (
    <div className="bg-white md:shadow-md rounded-lg md:p-5">
      <h2 className="font-bold text-xl leading-tight">
        Connect with us to discuss your property needs
      </h2>
      <form action={agency.lead_form_url} method="POST" id="agency-form-mobile">
        <input
          name="First Name"
          type="text"
          placeholder="First Name"
          className="text-sm appearance-none border border-gray-300 rounded-lg mt-2 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
        <input
          name="Surname"
          type="text"
          placeholder="Surname"
          className="text-sm appearance-none border border-gray-300 rounded-lg mt-2 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
        <input
          name="EmailDemoFrom"
          type="text"
          placeholder="Email"
          className="text-sm appearance-none border border-gray-300 rounded-lg mt-2 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
        <input
          name="ContactNumber"
          type="text"
          placeholder="Contact Number"
          className="text-sm appearance-none border border-gray-300 rounded-lg mt-2 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          required
          inputmode="numeric"
          pattern="[0-9]{10}"
          onKeyPress={event => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault()
            }
          }}
        />

        <div className="inline-block relative w-full">
          <select
            name="AssistIn"
            className="text-sm appearance-none border border-gray-300 rounded-lg mt-2 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="" disabled selected hidden>
              Contact me about
            </option>
            <option value="Selling my property">Selling my property</option>
            <option value="A property value assessment">
              A property value assessment
            </option>
            <option value="Renting my property">Renting my property</option>
            <option value="Buying a property">Buying a property</option>
          </select>
          <div className="pointer-events-none h-full absolute inset-y-0 right-0 flex items-center px-4 text-gray-700">
            <i className="w-full self-center fas fa-caret-down mt-2"></i>
          </div>
        </div>

        <input
          name="Suburb"
          type="text"
          required
          className="text-sm appearance-none border border-gray-300 rounded-lg mt-2 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Suburb"
        />
        <input
          name="Price"
          type="number"
          onKeyPress={event => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault()
            }
          }}
          required
          className="text-sm appearance-none border border-gray-300 rounded-lg mt-2 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Price"
        />

        <input placeholder="agency_slug" type="text" name="agency_slug" className="hidden" value={agency.slug}></input>
        <input placeholder="organisation_id" type="text" name="organisation_id" className="hidden" value={agency.organisation_id}></input>
        <input placeholder="utm_source" type="text" name="utm_source" className="hidden" value={cookies.get('utm_source')}></input>
        <input placeholder="utm_medium" type="text" name="utm_medium" className="hidden" value={cookies.get('utm_medium')}></input>
        <input placeholder="utm_campaign" type="text" name="utm_campaign" className="hidden" value={cookies.get('utm_campaign')}></input>
        <input placeholder="utm_content" type="text" name="utm_content" className="hidden" value={cookies.get('utm_content')}></input>

        <p className="text-xxs text-gray-500 mt-4">By submitting I agree to allow Flow to collect, store and process my data, as per the <a href="/privacypolicy" target="_blank" className="text-orange-500 underline">Privacy Policy</a></p>
        
        <button
          onClick={handleClickMobile}
          type="submit"
          className="text-sm bg-orange-500 hover:bg-orange-700 text-white font-medium py-2 px-4 rounded-lg mt-2 focus:outline-none focus:shadow-outline"
        >
          Send Message
        </button>
      </form>
    </div>
  )
}

export default AgencyResidentialFormMobile
